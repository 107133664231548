import React, { useEffect, useState } from 'react'
import CurrentlyWorkingFor from './CurrentlyWorkingForCard';
import CurrentlyReadingCard from './CurrentlyReadingCard';
import CurrentSideProjectCard from './CurrentSideProjectCard';
import { fontStyle } from '../../Styles';

const dataUrl = 'https://baileybrukettadotcomsourcedata.s3.us-east-2.amazonaws.com/currently_learning.json'
function Home() {
	const [loadedData, setLoadedData] = useState(null);
	const [errorMessage, setErrorMessage] = useState(null);

	useEffect(() => {
		load();
	}, []);

	const load = async () => {
		try{
			const response = await fetch(dataUrl);
			const result = await response.json()
			if(response.ok){
				setLoadedData(result);
			}else{
				setErrorMessage(result.message);
			}
		}catch(error){
			setErrorMessage(error.message);
		}
	};

	return (
		<div style={{ ...fontStyle }}>
			<h1>Bailey Bruketta</h1>
			<p style={{  borderRadius: '15%'}}>Software Engineer</p>
			
			<CurrentlyWorkingFor />
			<br />
			<CurrentSideProjectCard />
			<br />
			<CurrentlyReadingCard />
			{/*<br/>
			<LastMovie />*/}
		</div>
	);
}

export class HomeTitle extends React.Component{
	render(){
		return(
			<p style={{position: 'fixed', left: 0}}>Home</p>
		);
	}
}

export default Home
