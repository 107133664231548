import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function CurrentlyWorkingFor() {
  return (
    <Box sx={{ justifySelf: 'center', background: '#ffffff00' }}>
      <Card variant="outlined" sx={{ background: '#fffaf0eb', width: '90%', justifySelf: 'center' }}>
          <CardContent sx={{ textAlign: 'left' }}>
            <Typography sx={{ color: 'gray' }}>
              Currently Working For
            </Typography>
            <Typography variant="h5">
              Center for Applied Management Practices
            </Typography>
            <br />
            <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ color: 'gray' }}>
              Tech Stack 🛠️
            </AccordionSummary>
            <AccordionDetails variant="h6">
              <ul>
                <li>C# / .NET 9</li>
                <li>React with Typescript</li>
                <li>Microsoft SQL Server</li>
              </ul>
            </AccordionDetails>
            </Accordion>
          </CardContent>
      </Card>
    </Box>
  );
}

export default CurrentlyWorkingFor;
