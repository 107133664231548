import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { filter, map } from 'lodash';

function CommonCard({
  title,
  name,
  start,
  end,

  work,
  skills,
  media: unfilteredMedia,

  detailsText = 'Responsibilities 🗃️',
  divColor = 'red',
}) {
  const media = filter(unfilteredMedia, (x) => x !== '');


  return (
    <Box sx={{ background: '#ffffff00' }} className="jobExperienceItem">
      <Card variant="outlined" sx ={{ background: '#fffaf0eb', width: '90%', justifySelf: 'center' }}>
        <CardContent x={{ textAlign: "left" }}>
          <Box >
            <Typography variant="h5" sx={{ textAlign: 'left' }}>
              {name}
            </Typography>
            <div
              style={{
                width: "100%", // Adjust as needed
                height: "2px", // Adjust thickness
                background: `linear-gradient(to right, transparent, ${divColor}, transparent)`,
                marginTop: "4px", // Space between the <p> and the underline
              }}
            ></div>
          </Box>
          <Typography sx={{ textAlign: 'left', color: 'gray'}}>
            {title}
          </Typography>
          <Typography sx={{ textAlign: 'left'}}>
            {start} - {end}
          </Typography>
          <br />

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ color: 'gray' }}>
              Tech Stack 🛠️
            </AccordionSummary>
            <AccordionDetails>
              <ul style={{ textAlign: 'left'}}>
                {map(skills, (w) => (<li>{w}</li>))}
              </ul>
            </AccordionDetails>
          </Accordion>
          <br />

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ color: 'gray' }}>
              {detailsText}
            </AccordionSummary>
            <AccordionDetails>
              <ul style={{ textAlign: 'left'}}>
                {map(work, (w) => (<li>{w}</li>))}
              </ul>
            </AccordionDetails>
          </Accordion>

          {media.length > 0 && (
            <>
              <br />
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ color: 'gray' }}>
                  Media 📷
                </AccordionSummary>
                <AccordionDetails>
                  <ul className="mediaContainer">
                    {map(media, (m) => m.includes('.mp4')
                      ? (
                        <li className="mediaItem">
                          <video controls style={{ width: '30%', borderRadius: '5%'}}>
                            <source src={m} />
                          </video>
                        </li>
                      ) : (
                        <li className="mediaItem">
                          <img
                            style={{ width: '30%', borderRadius: '5%'}}
                            src={m}>

                          </img>
                        </li>
                      ))}
                  </ul>
                </AccordionDetails>
              </Accordion>
            </>
          )}

        </CardContent>
      </Card>
    </Box>
  );
}

export default CommonCard;
