import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DataGrid } from '@mui/x-data-grid';

const data = [
  { title: 'Zen Teaching of Instantaneous Awakening', author: 'Hui Hai (translated by Red Pine)', finished: 'November 2024' },
  { title: 'A Comprehensive Guide to Daoist NeiGong', author: 'Damo Mitchell', finished: 'August 2024' },
  { title: 'Nagarjuna\'s Fundamental Wisdom of the Middle Way', author: 'Nagarjuna (translated by Jay L. Garfield)', finished: 'June 2024' },
  { title: 'The Tao of Health, Longevity, & Immortality: the Teachings of Immortals Chung & Wu', author: '(translated by Eva Wong)', finished: 'May 2024' },
  { title: 'Instant Zen: Waking up in the Present', author: 'Foyan (translated by Thomas Cleary)', finished: 'May 2024' },
  { title: 'The Dhammapada', author: '(translated by Eknath Easwaran)', finished: 'May 2024' },
  { title: 'Cultivating Stillness', author: '(translated by Eva Wong)', finished: 'April 2024' },
  { title: 'Opening the Dragon Gate: The Making of a Modern Taoist Wizard', author: '', finished: 'March 2024' },
  { title: 'Enter Mo Pai: The Ancient Training of the Immortals', author: 'James Van Gelder', finished: 'December 2023' },
  { title: 'Flatland: A Romance of Many Dimensions', author: 'Edwin A. Abbott', finished: 'July 2023' },
  { title: 'CCRU 1997 - 2003', author: '', finished: 'August 2023' },
  { title: 'The Metamorphosis', author: 'Franz Kafka', finished: 'July 2023' },
  { title: 'Secret Oral Teachings in Tibetan Buddhist Sects', author: 'David A. Neel', finished: 'June 2023' },
  { title: 'The Epic of Gilgamesh', author: '', finished: 'June 2023' },
  { title: 'Autofac', author: 'Philip K. Dick', finished: 'May 2023' },
  { title: 'Do Androids Dream of Electric Sheep', author: 'Philip K. Dick', finished: 'April 2023' },
  { title: 'The Agony of Power', author: 'Jean Baudrillard (translated by Robert Hurley)', finished: 'March 2023' },
  { title: 'The Zen Teaching of Huang Po: On the Transmission of the Mind', author: '(translated by John Blofeld)', finished: 'February 2023' },
  { title: 'The Secret of the Golden Flower', author: '(translated by Thomas Cleary)', finished: 'February 2023' },
  { title: 'The Gateless Gate: All 48 Koans with Commentary by Ekai, called Mumon', author: '', finished: 'January 2023' },
  { title: 'Huangdi Yifujing / Yellow Emperor\'s Hidden Talisman Classic', author: '', finished: 'January 2023' },
  { title: 'The Heiroglyphical Monad', author: 'John Dee', finished: 'December 2022' },
  { title: 'The Diamond Sutra', author: '(translated by Trepitaka Kumarajiva)', finished: 'December 2022' },
  { title: 'Timaeus & Critias', author: 'Plato', finished: 'November 2022' },
  { title: 'Tao Te Ching', author: 'Lao Tzu', finished: 'October 2022' }
];

const otherBooks = [
  { title: 'Programming Entity Framework: Code First', author: 'Julia Lerman & Rowan Miller' },
  { title: 'Venerable Master Hua\'s Talks on Dharma:  Volume I', author: '' },
  { title: 'The Vimalakirti Sutra', author: '(translated by Burton Watson)' },
  { title: 'Gorgias', author: 'Plato' },
  { title: 'Fuzzy Data Matching with SQL: Enhancing Data Quality and Query Performance', author: 'Jim Lehmer' },
  { title: 'Paradise Lost', author: 'John Milton' },
  { title: 'Qigong, the Secret of Youth: Da Mo\'s Muscle/Tendon Changing and Marrow/Brain Washing Classics', author: 'Dr. Yang, Jwing-Ming' },
  { title: 'Original Tao: Inward Training (nei-yeh) and the Foundations of Taoist Mysticism', author: 'Harold D. Roth' },
  { title: 'Efficient C++: Performance Programming Techniques', author: 'Dov Bulka, David Mayhew' },
  { title: 'The Shurangama Sutra', author: 'Hsuan Hua' },
  { title: 'Understanding Reality', author: 'Chang Po-tuan (translated by Thomas Cleary)' },
  { title: 'The Shurangama Sutra, The Fifty Skandha-Demon States', author: 'Hsuan Hua' },
  { title: 'The Ego and Its Own', author: 'Max Stirner' },
  { title: 'The Practice of The Six Yogas of Naropa', author: 'Glenn H. Mullin' },
  { title: 'Siddhartha', author: 'Hermann Hesse' },
  { title: 'Dynamics of Time and Space: Transcending Limits on Knowledge', author: 'Tarthang Tulku' },
  { title: 'The Golden Light Sutra', author: '' },
  { title: 'The Secret Book of Artephius', author: 'Ibn Ar Tafiz' },
  { title: 'Taoist Meditation: Methods for Cutlivating a Healthy Mind and Body', author: '(translated by Thomas Cleary)' },
  { title: 'Lasers, The Light Fantastic', author: 'Clayton L. Hallmark' },
  { title: 'Tao and Longevity: Mind-Body Transformation', author: 'Huai-Chin Nan (translated by Wen Kuan Chu, Ph.D)' },
  { title: 'Principles of Auditing', author: 'Walter B. Meigs, E. John Larsen, Robert F. Meigs' },
  { title: 'The Count of Monte Cristo', author: 'Alexander Dumas' },
  { title: 'The Birds and Other Plays', author: 'Aristophanes' },
  { title: 'Against Heresies', author: 'Irenaeus' },
  { title: 'Anti-Oedipus', author: 'Gilles Deleuze & Felix Guattari' },
  { title: 'The Trial', author: 'Franz Kafka' },
  { title: 'Taoist Yoga: Aclhemy and Immortality', author: 'Lu K\'uan Yu' },
  { title: 'Wholeness and the Implicate Order', author: 'David Bohm' },
  { title: 'The Commissioner', author: 'Richard Dougherty' },
  { title: 'Locked On', author: 'Tom Clancy' },
  { title: 'The White Island and the Black Book', author: 'Colleen D. Clements, PhD' },
  { title: 'The Order of the Dragon', author: 'Colleen D. Clements, PhD' },
  { title: 'Triumphal Chariot of Antimony', author: 'Basil Valentine' },
  { title: 'Enuma Elish: The Babylonian Creation Epic', author: 'Timothy J. Stephany' },
  { title: '1491: New Revelations of the Americas Before Columbus', author: 'Charles C. Mann' },
  { title: 'The Tibetan Yogas of Dream and Sleep', author: 'Tenzin Wangyal Rinpoche' },
  { title: 'The Lotus Sutra', author: '(translated by Burton Wilson)' },
  { title: 'The Republic and the Laws', author: 'Cicero' },
  { title: 'The Alchemist', author: 'Paulo Coelho' },
  { title: 'Fanged Noumena', author: 'Nick Land' },
  { title: 'The Marriage of Heaven and Hell: In Full Color', author: 'William Blake' },
]

function CurrentlyReadingCard() {
  return (
    <Box sx={{ justifySelf: 'center', background: '#ffffff00' }}>
      <Card variant="outlined" sx={{ background: '#fffaf0eb', width: '90%', justifySelf: 'center' }}>
        <CardContent sx={{ textAlign: 'left' }}>
          <Typography sx={{ color: 'gray' }}>
            Currently Reading
          </Typography>
          <Typography variant="h5" >
            The Zen Teaching of Bodhidharma
          </Typography>
          <Typography sx={{ marginLeft: '20px', color: 'gray' }} variant="h7">
            translated by Red Pine
          </Typography>
          <br />
          <br />

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              Reading List 📖
            </AccordionSummary>
            <AccordionDetails>
              <DataGrid
                rows={data}
                sx={{ width: '100%', justifySelf: 'center'}}
                columns={[{
                  field: 'title', headerName: 'Title', //width: 600
                }, {
                  field: 'author', headerName: 'Author', //width: 350
                }, {
                  field: 'finished', headerName: 'Finished', //width: 150
                }]}
                getRowId={(r) => r.title}
                density='compact'
                //disableColumnResize
              />
            </AccordionDetails>
          </Accordion>
          <br />

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              The Rest of My Library 📚
            </AccordionSummary>
            <AccordionDetails>
              <DataGrid
                rows={otherBooks}
                sx={{ width: '100%', justifySelf: 'center'}}
                columns={[{
                  field: 'title', headerName: 'Title', //width: 700
                }, {
                  field: 'author', headerName: 'Author', //width: 350
                }]}
                getRowId={(r) => r.title}
                density='compact'
                //disableColumnResize
              />
            </AccordionDetails>
          </Accordion>
        </CardContent>
      </Card>
    </Box>
  );
}

export default CurrentlyReadingCard;