import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { filter, map } from 'lodash';

function EducationCard({
  title,
  name,
  start,
  end,

  work,
  skills,
  media: unfilteredMedia,

  detailsText = 'Responsibilities 🗃️'
}) {
  const media = filter(unfilteredMedia, (x) => x !== '');


  return (
    <Box sx={{ background: '#ffffff00' }} className="jobExperienceItem">
      <Card variant="outlined" sx={{ background: '#fffaf0eb', width: '90%', justifySelf: 'center' }}>
        <CardContent x={{ textAlign: "left" }}>
          <Typography variant="h5" sx={{ textAlign: 'left'}}>
            {name}
          </Typography>
          <Typography sx={{ textAlign: 'left', color: 'gray'}}>
            {title}
          </Typography>
          <Typography sx={{ textAlign: 'left'}}>
            {start} - {end}
          </Typography>
          <br />

          <ul style={{ textAlign: 'left'}}>
            {map(work, (w) => (<li>{w}</li>))}
          </ul>

        </CardContent>
      </Card>
    </Box>
  );
}

export default EducationCard;