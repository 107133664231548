import React from 'react';
import Typography from '@mui/material/Typography';
export function TitleText({titleText}) {
    return(
        
        <div style={{position: 'fixed', top: 15}}>
            <Typography style={{color: 'rgba(0,0,0,.6)', fontSize: 20}}>{titleText}</Typography>
            
        </div>
        
    );
}
export default TitleText;