import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

function Contact() {
  return (
    <div >
        <h1>Contact</h1>
        <Box sx={{ justifySelf: 'center', background: '#ffffff00' }}>
          <Card variant="outlined" sx={{ background: '#fffaf0eb' }}>
            <CardContent sx={{ textAlign: 'left' }}>
              <Typography sx={{ color: 'gray' }}>
                Email ✉️
              </Typography>
              <Typography variant="h7">
                bruketta.bailey@gmail.com
              </Typography>
              <br />
              <br />

              <Typography sx={{ color: 'gray' }}>
                Phone 📱
              </Typography>
              <Typography variant="h7">
                1 (309) 259 0896
              </Typography>

            </CardContent>
          </Card>
        </Box>
    </div>
  );
}

export default Contact;
