import React from 'react'
import LastMovie from './Home/LastMovie';
class About extends React.Component{
    render(){
        return (
            <div >
                <h1>About</h1>
                <p style={{ borderRadius: '15%'}}>This site was built with React.js and the Material UI Library.</p>
                <br />
                <LastMovie />
            </div>
            
        );
    }
}
export default About