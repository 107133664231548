import './App.css';
import React from 'react';
import MainPage from './Components/MainPage.js'
import Home from './Components/Home/Home';

const colors = {
  PRIMARY_COLOR: '#F17F2A',
  SECONDARY_COLOR: '#9d8f85',
  BORDER_COLOR: '#d1c9c3',
  BACKGROUND_COLOR: '#FAF4F0',
  GOOD_ORANGE: '#F17F2A'
}

class App extends React.Component {
  
  componentDidMount(){
    document.body.style.backgroundColor = colors.BACKGROUND_COLOR;
  }


  render(){


    return (
      <div className="App" style={{  }} >
        <div>

        < MainPage MyComponent={<Home/>} /> 
  
      </div>
      </div>
    );
  }
}

export default App;
