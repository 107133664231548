import React from 'react'
import EducationCard from './EducationCard';
import { map } from 'lodash';
const dataUrl = 'https://baileybrukettadotcomsourcedata.s3.us-east-2.amazonaws.com/education.json'
class Education extends React.Component{
    constructor(props){
        super(props);
        this.setLoadedData   = this.setLoadedData.bind(this);
        this.setErrorMessage = this.setErrorMessage.bind(this);
        this.state        = {
            loadedData: [],
            errorMessage: null
        }
    }
    setLoadedData(a){this.setState({loadedData: a.stuff })}
    setErrorMessage(a){this.setState({errorMessage: a})}
    componentDidMount(){
        this.load();
    }
    async load(){
        try{
            console.log(dataUrl);
            const response = await fetch(dataUrl);
            const result = await response.json()
            if(response.ok){
                this.setLoadedData(result);
            }else{
                this.setErrorMessage(result.message);
            }
        }catch(error){
            this.setErrorMessage(error.message);
        }
    }
    render(){
        if(this.state.loadedData){
            return(
                <div className = "wholeworkexperiencecontainer">
                    {map(this.state.loadedData, (data) => (
                        <>
                            <EducationCard
                                title={data.title}
                                name={data.job}
                                start={data.start}
                                end={data.end}
                                work={data.work}
                                skills={data.skills}
                                media={data.media}
                                detailsText='Details 📄'
                            />
                            <br />
                        </>
                    ))}
                </div>
               
            );
        }else if (this.state.errorMessage){
            return(
                <div>
                    <p style={{ textAlign: 'center' }}>{this.state.errorMessage}</p>
                </div>
            )
        }
        return (
            <p>Loading</p>
        );
    }
}
export default Education