import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

function LastMovie() {
  return (
    <Box sx={{ justifySelf: 'center', background: '#ffffff00' }}>
      <Card variant="outlined" sx={{ background: '#fffaf0eb' }}>
        <CardContent sx={{ textAlign: 'left' }}>
          <Typography sx={{ color: 'gray' }}>
            Last Movie I Watched 📽️
          </Typography>
          <Typography variant="h5">
            Napoleon (2023)
          </Typography>
          <br />
          <Typography>
            <a target="_blank" href="https://boxd.it/9gfCX">My Letterboxd</a>
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}

export default LastMovie;