import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import AccordionSummary from '@mui/material/AccordionSummary';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function CurrentSideProjectCard() {
  return (
    <Box sx={{ justifySelf: 'center', background: '#ffffff00' }}>
      <Card variant="outlined" sx={{ background: '#fffaf0eb', width: '90%', justifySelf: 'center' }}>
        <CardContent sx={{ textAlign: 'left' }}>
          <Typography sx={{ color: 'gray' }}>
            Current Side Project
          </Typography>
          <Typography variant="h5">
            Airways
          </Typography>
          <Typography variant="h7">
            An open world game about hot air balloons and mail delivery
          </Typography>
          <br />
          <br />
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ color: 'gray' }}>
              Tech Stack 🛠️
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                <li>Unreal Engine 5</li>
                <li>C++</li>
              </ul>
            </AccordionDetails>
          </Accordion>
        </CardContent>
      </Card>
    </Box>
  );
}

export default CurrentSideProjectCard;